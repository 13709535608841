<template>
  <!-- begin:: Footer -->
  <div
    class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
    id="kt_footer"
  >
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': isFluid() }"
    >
      <div class="kt-footer__copyright">
        2020 &nbsp;&copy;&nbsp;
        <a
          href="#"
          target="_blank"
          class="kt-link ml-1"
        >
          DatND - 
        </a>
        <a
          href="https://www.facebook.com/datnd.197"
          target="_blank"
          class="kt-link ml-1"
        >
          <v-icon small="">fab fa-facebook-square</v-icon>&nbsp;
        </a>
        
        <a class="kt-link ml-1" target="_blank" href="mailto:datnd997@gmail.com">
           <v-icon small="">fas fa-envelope</v-icon>&nbsp;
        </a>
        <a class="kt-link ml-1" target="_blank" href="tel:+84904545084">
           <v-icon small="">fas fa-phone-square</v-icon>
        </a>
      </div>
      <div class="kt-footer__menu">
        <a
          
       
          class="kt-footer__menu-link kt-link"
          >Goldfruit Staff - </a>
          <a href="https://www.facebook.com/GoldFruitHaNoi/" class="kt-link ml-1" target="_blank" >
           <v-icon small="">fab fa-facebook-square</v-icon>
        </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  methods: {
    /**
     * Check if footer container is fluid
     * @returns {boolean}
     */
    isFluid() {
      return this.layoutConfig("footer.self.width") === "fluid";
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
